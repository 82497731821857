<template>
  <!-- Desc: Platform view at the campaign level of campaign details -->
  <div
    class="card min-height-card u-flex-direction-column u-width-100 u-display-flex"
  >
    <div
      v-if="confirmStatusChange"
      class="u-spacing-pt-l"
    >
      <component
        :is="confirmStatusChange.component"
        v-bind="confirmStatusChange.props(this)"
      />
    </div>
    <div
      v-if="selections.length && !adjustBids && !confirmStatusChange"
      class="u-spacing-pt-l"
    >
      <actions-bar
        :close-fn="closeActionBar"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="allowedActions"
        @open-workspace="openActionsWorkspace"
      />
    </div>
    <tableWrapper
      :table-data-component-key="tableDataComponentKey"
      :class="selections.length && 'campaign_details_table_header_padding'"
      :date-filter="dateFilter"
      :tab-metadata="tabMetadata"
      :widget="widget"
      :header-options="headerOptions"
      :context="context"
      :entity-config="entityConfig"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import radioSelectionWithInput from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/radioSelectionWithInput.vue';
const tableWrapper = () =>
  import('@/pages/entity_details/common_components/tableWrapper.vue');
const actionsBar = () => import('@/components/widgets/actionsBar.vue');
const confirmStatusChangeComponent = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/approveRec.vue'
  );

export default {
  components: {
    tableWrapper,
    actionsBar,
    radioSelectionWithInput
  },
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    }
  },
  data() {
    const context = this;
    return {
      getState: null,
      selections: [],
      updateMetadata: null,
      tableGridAPI: null,
      context,
      widget: {},
      tabConfig: null,
      confirmStatusChange: null
    };
  },
  computed: {
    tableDataComponentKey() {
      return this.$store.getters[
        this.tabConfig?.apiConfigs?.getTableDataComponentKeyGetter
      ];
    },
    campaignInfo() {
      return this.$store.getters[this.getState];
    },
    headerOptions() {
      const obj = {};
      let headerOptions = [];
      if (this.tabMetadata?.widgets[0]) {
        obj[this.tabMetadata.widgets[0]] = {
          props: {
            // enableDownload: true,
            headerOptions: headerOptions,
            // enableDownload: true,
            enableManageColumns: !this.selections.length,
            headerContainerGridStyles: {
              display: this.selections.length ? 'none' : 'flex',
              'align-items': 'center',
              'justify-content': 'flex-end'
            },
            searchParams: {
              show: false
              // show: true,
              // searchDimension: 'ams_cm_adgroup_adgroup_name'
            },
            ...(this.tabConfig?.disableRowSelection
              ? []
              : {
                  rowSelection: {
                    mode: 'multiple',
                    onSelectionChanged: this.handleSelectionChange
                  }
                }),
            rowHeight: 45
          }
        };
      }
      return obj;
    }
  },
  created() {
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.widget = {
      ':widget': this.tabMetadata.widgets[0],
      ':orderByList': [],
      ':dimensionNameValueList': [],
      keepExistingDimensionNameValueList: true,
      replaceEntityInOperationsObject: {
        ':system': `campaign_${this.entityId}`
      },
      replaceEntityInDimensionNameValueList: {
        ':campaign_id': this.entityId
      }
    };
    Vue.component('confirmStatusChangeComponent', confirmStatusChangeComponent);
  },
  methods: {
    handleSelectionChange(item1, item2) {
      this.tableGridAPI = item2?.api;
      this.selections = item1;
    },
    openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection,
      selections
    ) {
      if (actionId === 'editStatus') {
        this.confirmStatusChange = {
          component: 'confirmStatusChangeComponent',
          props: (vueRef) => ({
            config: {
              heading: `EDIT STATUS(${vueRef.selections.length} item${
                vueRef.selections.length > 1 ? 's' : ''
              } selected)`,
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              message: `Status for selected item${
                vueRef.selections.length > 1 ? 's' : ''
              } will be ${dropDownSelection[0].value}`,
              apply: () => {
                this.callStateChange(dropDownSelection, selections);
              },
              handleCancel: () => {
                this.closeActionBar();
              }
            }
          })
        };
      } else {
        this[actionId] = true;
      }
    },
    closeActionBar() {
      this.tableGridAPI?.deselectAll();
      this.selections = [];
      this.adjustBids = null;
      this.confirmStatusChange = null;
    },
    callStateChange(dropDownSelection, selections) {
      if (this.tabConfig?.changeStatus) {
        return this.tabConfig?.changeStatus?.(
          this,
          dropDownSelection,
          selections
        );
      }
    }
  }
};
</script>

<style lang="css" scoped>
.min-height-card {
  min-height: 60vh;
}
</style>

<style lang="css">
.campaign_details_table_header_padding .header-container-cwc {
  padding: 0px;
}
</style>
